import React from 'react'
import { SimpleGrid, Card, Link, Image, Box, } from "@chakra-ui/react";
import listen from '../../Data/listen';
import MetaDecorator from '../../Components/Utils/MetaDecorator';
import metaData from '../../Data/metaInfo';





function ListenCard({ url, img, }) {
  return (
    <Card bg={'transparent'} _hover={{ filter: "brightness(1)", transform: 'scale(1.005)'}} filter={"brightness(0.8)"} overflow={'hidden'} borderRadius={'md'}>
      <Link href={url} isExternal >
        <Image w='100%' objectFit={'cover'} src={img} alt="description" />
      </Link>

    </Card>
  );
}



const Listen = () => {
  return (

    <Box maxW={'1200px'} mx={'auto'} mt={10}>
      <MetaDecorator title={metaData.listenPage.title} description={metaData.listenPage.description} />
      <SimpleGrid minChildWidth='200px' spacing={8} mb={12} p={4}  >
        {listen.map((item) => (
          <ListenCard
            key={item.id}
            url={item.url}
            img={item.img}

          />
        ))
        }
      </SimpleGrid>
    </Box>

  )
}

export default Listen