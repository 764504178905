import React from 'react'
import { SimpleGrid, Box, Image, Link, Text, Card, CardBody, Stack, Heading, AspectRatio } from "@chakra-ui/react";
import read from '../../Data/read';
import MetaDecorator from '../../Components/Utils/MetaDecorator';
import metaData from '../../Data/metaInfo';




function ReadCard({ url, img, title, description }) {
  return (
    <Card bg={'transparent'} _hover={{ filter: "brightness(1)", transform: 'scale(1.005)' }} filter={"brightness(0.8)"}>
      <Link href={url} isExternal style={{ textDecoration: 'none' }}>
        <CardBody textAlign="left">
          <AspectRatio overflow={'hidden'} borderRadius={'md'}>
            <Image
              objectFit={'cover'}
              src={img}
              alt={description}
            />
          </AspectRatio>

          <Stack mt='6' spacing='3'>
            <Heading size='md' color={'#eee'}>{title}</Heading>
            <Text fontSize={"12px"} color={'#aaa'}>{description}</Text>
          </Stack>
        </CardBody>
      </Link>

    </Card>
  );
}




const Read = () => {
  return (
    <Box maxW="1100px" mx={'auto'} mt={10}>
      <MetaDecorator title={metaData.readPage.title} description={metaData.readPage.description} />
      <SimpleGrid minChildWidth='250px' spacing={8} mb={12} p={4}    >
        {read.map((item) => (
          <ReadCard
            key={item.id}
            url={item.url}
            img={item.img}
            title={item.title}
            description={item.description}
          />
        ))}
      </SimpleGrid>
    </Box>
  )
}

export default Read