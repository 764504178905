import React from 'react'
import Typewriter from 'typewriter-effect';
import { Flex } from '@chakra-ui/react'
import MetaDecorator from '../Utils/MetaDecorator'
import metaData from '../../Data/metaInfo'



const SuruatiBakar = () => {
  return (

    <Flex mx={5} h={'70vh'} align="center" justify="center" fontSize={60} color="white">
      <MetaDecorator title={metaData.homePage.title} description={metaData.homePage.description} />
      <Typewriter
        options={{
          strings: ['Allow your sense of awe to flourish', 'Read', 'Watch', 'Listen', 'Discover', 'Fuzzy Klarity - Curating the best of Science :)'],
          autoStart: true,
          loop: true,
          delay: 50,
          deleteSpeed: 30,
        }}
      />
    </Flex>
  )
}

export default SuruatiBakar