let COUNT = 0
const shorts = [
    {
        id: COUNT++,
        url : 'https://www.youtube.com/shorts/OnNCOndrUL4'
    },
    {
        id: COUNT++,
        url : 'https://www.youtube.com/shorts/U4rGnPeNaVg'
    },
    {
        id: COUNT++,
        url : 'https://www.youtube.com/shorts/Zxf2MgYCOm0'
    },
    {
        id: COUNT++,
        url : 'https://www.youtube.com/shorts/IEFlaw19yLk'
    },
    {
        id: COUNT++,
        url : 'https://www.youtube.com/shorts/Pw3gFL-YcCI'
    },
    {
        id: COUNT++,
        url : 'https://www.youtube.com/shorts/Skve2Z-pAcc'
    },
    {
        id: COUNT++,
        url : 'https://www.youtube.com/shorts/yN-CSqe9QoE'
    },
    {
        id: COUNT++,
        url : 'https://www.youtube.com/shorts/u042cZoYyRM'
    },
    {
        id: COUNT++,
        url : 'https://www.youtube.com/shorts/KnCcHeVfFXg'
    },
    {
        id: COUNT++,
        url : 'https://www.youtube.com/shorts/WFbYgCypZ-Y'
    },
    {
        id: COUNT++,
        url : 'https://www.youtube.com/shorts/KWVCGliCEUk'
    },
    {
        id: COUNT++,
        url : 'https://www.youtube.com/shorts/gIDU52bK8JM'
    },
    {
        id: COUNT++,
        url : 'https://www.youtube.com/shorts/Xed60vrCuVM'
    },
    {
        id: COUNT++,
        url : 'https://www.youtube.com/shorts/HqNcgLskf3w'
    },
    {
        id: COUNT++,
        url : 'https://www.youtube.com/shorts/gFvXnm3NOLk'
    },
    {
        id: COUNT++,
        url : 'https://www.youtube.com/shorts/iPhflplLtYg'
    },
    {
        id: COUNT++,
        url : 'https://www.youtube.com/shorts/g47x7Qswxfk'
    },
    {
        id: COUNT++,
        url : 'https://www.youtube.com/shorts/SsPPIaq59ZU'
    },
    {
        id: COUNT++,
        url : 'https://www.youtube.com/shorts/uUGzrS5tpLc'
    },
    {
        id: COUNT++,
        url : 'https://www.youtube.com/shorts/zSpuTwEOpOg'
    },
    {
        id: COUNT++,
        url : 'https://www.youtube.com/shorts/HfBWFQIKHFk'
    },
    {
        id: COUNT++,
        url : 'https://www.youtube.com/shorts/hTcjxfKTiJc'
    },
    {
        id: COUNT++,
        url : 'https://www.youtube.com/shorts/RHq89GJb8og'
    },
    {
        id: COUNT++,
        url : 'https://www.youtube.com/shorts/__bp6TInvJE'
    },
    {
        id: COUNT++,
        url : 'https://www.youtube.com/shorts/DMyXgw-t0W4'
    },
    {
        id: COUNT++,
        url : 'https://www.youtube.com/shorts/tlFh_7iPgfU'
    },
    {
        id: COUNT++,
        url : 'https://www.youtube.com/shorts/4Lhtn4g_ZRY'
    },
    {
        id: COUNT++,
        url : 'https://www.youtube.com/shorts/FWp9JXdp0zM'
    },
    {
        id: COUNT++,
        url : 'https://www.youtube.com/shorts/YL9h_aRDESA'
    },
    {
        id: COUNT++,
        url : 'https://www.youtube.com/shorts/KQIpqCuKmAY'
    },
    {
        id: COUNT++,
        url : 'https://www.youtube.com/shorts/veH1BeQ6UkU'
    },
    {
        id: COUNT++,
        url : 'https://www.youtube.com/shorts/wodPxsd4txs'
    },
    {
        id: COUNT++,
        url : 'https://www.youtube.com/shorts/crEaYmapD44'
    },
    {
        id: COUNT++,
        url : 'https://www.youtube.com/shorts/aSg85yQ_0xw'
    },
    {
        id: COUNT++,
        url : 'https://www.youtube.com/shorts/Ycyf07_rpZo'
    },
    {
        id: COUNT++,
        url : 'https://www.youtube.com/shorts/bNYkAhbWKqI'
    },
    {
        id: COUNT++,
        url : 'https://www.youtube.com/shorts/-FKNbdY2OXI'
    },
    {
        id: COUNT++,
        url : 'https://www.youtube.com/shorts/Qz6P0uvNcSM'
    },
    {
        id: COUNT++,
        url : 'https://www.youtube.com/shorts/r8CkAeOE43c'
    },
    {
        id: COUNT++,
        url : 'https://www.youtube.com/shorts/jtZqwSXAAGg'
    },
    {
        id: COUNT++,
        url : 'https://www.youtube.com/shorts/bLApqUO27qY'
    },
    {
        id: COUNT++,
        url : 'https://www.youtube.com/shorts/L4WSKf6NZrc'
    },
    {
        id: COUNT++,
        url : 'https://www.youtube.com/shorts/g0RVj2mbs5g'
    },
    {
        id: COUNT++,
        url : 'https://www.youtube.com/shorts/Faonk4RxRnA'
    },
    {
        id: COUNT++,
        url : 'https://www.youtube.com/shorts/9y446jqrzHg'
    },
    // {
    //     id: COUNT++,
    //     url : ''
    // },
]

export default shorts