import React from 'react'
import { useParams, Link as ReactRouterLink } from 'react-router-dom'
import {
  Box,
  HStack,
  Spacer,
  Heading,
  SimpleGrid
} from '@chakra-ui/react'

import { Link as ChakraLink } from '@chakra-ui/react';

import { WatchCard } from '../Category/WatchCard'

// Cards from watch categories
import watch_data from '../../Data/watch'
import MetaDecorator from '../Utils/MetaDecorator';
import metaData from '../../Data/metaInfo';

const cardsByCategory = []
let card_key = 0
watch_data.forEach((card) => {
    const { category } = card;

    category.forEach((cat) => {
        if (!cardsByCategory[cat]) {
            cardsByCategory[cat] = [];
        }
        card["key"] = card_key
        card_key++
        cardsByCategory[cat].push(card);
    });
});

const WatchSection = (props) => {
  const { categoryName } = useParams()
  const mastHead = categoryName.replace(/-/g, ' ').replace(/\b\w/g, c => c.toUpperCase())

  return (
    <Box maxW={'1200px'} mx={'auto'} mt={10}>
      <MetaDecorator title={`Watch > ${mastHead} | ${metaData.homePage.title}`} description={metaData.watchPage.description} />
      <HStack>
        <Box w={1} bg={'cyan.500'} borderRadius={'md' } ml={4}>&nbsp;</Box>
        <Heading fontSize={'1.4rem'} mb={2} pl={1} color={'gray.500'}>{`Hall of ${mastHead}`}</Heading>
        <Spacer />
        <ChakraLink as={ReactRouterLink} to={'/watch'} pr={4} color={'teal.500'}>Back</ChakraLink>
      </HStack>
      <SimpleGrid minChildWidth='166px' spacing={8} mb={12} p={4}>
        {cardsByCategory[mastHead].map((card) => (
          <WatchCard
            key={card.key}
            image={card.img}
            title={card.title}
            modalTitle={card.modalTitle}
            description={card.description}
            videoSource={card.src}
          >
          </WatchCard>

        ))}
      </SimpleGrid>
    </Box>
  )
}

export default WatchSection