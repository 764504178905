import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    categories: [],
    contents: [],
    checked: [],
};

const contentSlice = createSlice({
    name: "content",
    initialState,
    reducers: {
        setCaegories: (state, action) => {
            state.categories = action.payload;
        },
        setContents: (state, action) => {
            state.contents = action.payload;
        },
        setChecked: (state, action) => {
            state.checked = action.payload
        }
    },
});


export const {setCaegories, setContents, setChecked} = contentSlice.actions;

export default contentSlice.reducer