import React from 'react';
// import ReactDOM from 'react-dom/client';
import { hydrate, render } from "react-dom";
import './index.css';
import App from './App';
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import { Provider } from 'react-redux';
import store from './redux/store';

const theme = extendTheme({
  fonts: {
    Text: "'Montserrat', sans-serif",
    heading: "'Roboto', sans-serif",
  },
});

const MainApp = (
  <React.StrictMode>
    <Provider store={store}>
    <ChakraProvider theme={theme}>
      <App />
    </ChakraProvider>
    </Provider>
  </React.StrictMode>
)

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(MainApp, rootElement);
} else {
  render(MainApp, rootElement);
}


