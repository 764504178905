let COUNT = 0

const read = [
    {
        id: COUNT++,
        url: "http://eyes.nasa.gov",
        img: "https://eyes.nasa.gov/server/highlights/eotss_images/juno_320x240.jpg?1681660367268",
        title: "NASA’s Eyes",
        description: "Experience Earth, our solar system, the universe and the spacecraft exploring them, with immersive apps for Mac, PC and mobile devices."
    },
    {
        id: COUNT++,
        url: "https://ciechanow.ski/lights-and-shadows/",
        img: "/images/bartoz.png",
        title: "Bartosz Ciechanowski",
        description: "With its captivating interactive articles on physics, math, and engineering, Jakub Ciechanowski's blog 'Ciechanow.ski' is a must-visit for anyone looking to explore the limitless possibilities of a blog."
    },
    {
        id: COUNT++,
        url: "https://www.arvindguptatoys.com/toys-from-trash.php",
        img: "/images/arvind_gupta.png",
        title: "Arvind Gupta Toys",
        description: "Founded by the legendary toy inventor, author, and science popularizer Arvind Gupta in 1980, non-profit  \"Toys from Trash\" aims to teach children the principles of science using toys made from trash."
    },
    {
        id: COUNT++,
        url: "https://explorabl.es/",
        img: "/images/explorable.png",
        title: "Explorable Explanations",
        description: "Welcome to Explorable Explanations, a hub for learning through play! We’re a disorganized “movement” of artists, coders & educators who want to reunite play and learning."
        
    },
    {
        id: COUNT++,
        url: "https://betterexplained.com/",
        img: "https://betterexplained.com/wp-content/uploads/math-analogies/math-analogies-jpg.023.jpeg",
        title: "Better Explained",
        description: "Let’s move past dry facts and share the Aha! moments that make learning fun and memorable. If an intuition-first approach resonates with you, you’ll feel right at home. There’s no pretenses, no fuddy teacher, just an excited friend sharing what actually made an idea click."
        
    },
    {
        id: COUNT++,
        url: "https://hiddenheroes.netguru.com/",
        img: "https://pbs.twimg.com/media/FuKEWBoXoAIz2nJ?format=jpg&name=large",
        title: "Hidden Heroes",
        description: "Hidden Heroes is a tribute to innovators who set the foundations on which we build vital solutions for the modern world. Their inventions make the world run, but their stories remain hidden. Until now."
        
    },
    {
        id: COUNT++,
        url: "https://xkcd.com/2586/",
        img: "/images/xkcd.png",
        title: "xkcd",
        description: "A webcomic of romance, sarcasm, math, and language."  
    },
    {
        id: COUNT++,
        url: "https://www.lesswrong.com/hpmor",
        img: "/images/LessWrong.jpg",
        title: "LessWrong",
        description: "LessWrong is an online forum and community dedicated to improving human reasoning and decision-making. Each day, we aim to be less wrong about the world than the day before."  
    },
    {
        id: COUNT++,
        url: "https://abstrusegoose.com/",
        img: "/images/image.png",
        title: "Abstruse Goose",
        description: "Abstruse Goose is a webcomic that uses clever, satirical humor to explore science, technology, and society. The comics often feature surreal or absurd scenarios and illustrations." 
    },
    {
        id: COUNT++,
        url: "https://mathigon.org/",
        img: "https://mathigon.org/content/graph-theory/hero.jpg",
        title: "Mathigon",
        description: "Mathigon is part interactive textbook and part virtual personal tutor. Using cutting-edge technology and an innovative new curriculum, we want to make learning mathematics more active, personalised and fun."  
    },
    {
        id: COUNT++,
        url: "https://visualize-it.github.io/index.html",
        img: "https://visualize-it.github.io/images_webp/bernoulli_percolation.webp",
        title: "Visualize It",
        description: "All simulations here were developed by ChanRT. Fork me at GitHub! Everything has been programmed using vanilla JavaScript. You are free to use these simulations for educational purposes. However, an acknowledgement would be great!"   
    },
    {
        id: COUNT++,
        url: "https://retractionwatch.com/",
        img: "https://i0.wp.com/retractionwatch.com/wp-content/uploads/2021/07/rw.jpeg?resize=768%2C576&ssl=1",
        title: "Retraction Watch",
        description: "Tracking retractions as a window into the scientific process."  
    },
    {
        id: COUNT++,
        url: "https://www.quantamagazine.org/",
        img: "/images/Quanta.jpg",
        title: "Quanta Magazine",
        description: "Illuminating basic science and math research through public service journalism."   
    },
    {
        id: COUNT++,
        url: "https://www.symmetrymagazine.org/",
        img: "/images/Symmetry.jpg",
        title: "Symmetry Magazine",
        description: "Symmetry is your view into the world of particle physics. Symmetry is a joint publication of Fermi National Accelerator Laboratory and SLAC National Accelerator Laboratory."  
    },
    {
        id: COUNT++,
        url: "https://www.whatismissing.org/",
        img: "https://www.whatismissing.org/_next/image?url=https%3A%2F%2Fimages.ctfassets.net%2F9833dwig2thk%2F6z0uN53NRSYVzX1rMWLRMR%2Fef456e7f49fe1e0f095a6e798445c051%2Fcropped_cropped.jpg%3Fw%3D1000%26q%3D70&w=1920&q=75",
        title: "What is Missing?",
        description: "Maya Lin's What Is Missing? is a science-based art project that memorializes the sixth mass extinction of species, highlights habitat degradation, and promotes conservation efforts to reduce carbon emissions and protect biodiversity."  
    },
    {
        id: COUNT++,
        url: "https://www.solarsystemscope.com/",
        img: "https://www.solarsystemscope.com/images/background_model.jpg",
        title: "Solar System Scope",
        description: "Solar System Scope is a web-based 3D simulation of the Solar System and night sky in real-time with extensive information about each celestial body."  
    },
    {
        id: COUNT++,
        url: "https://www.snexplores.org/",
        img: "https://www.snexplores.org/wp-content/uploads/2023/04/1440_LL_frog_feat-1030x580.jpg",
        title: "Science News Explores",
        description: "Science News Explores is an award-winning publication dedicated to providing topical stories on current events in science, technology, engineering and math (STEM) for children ages 9 and up, their parents and educators."   
    },
    {
        id: COUNT++,
        url: "https://scibabe.com/mos-kary-mullis-nobel-disease/",
        img: "https://i0.wp.com/scibabe.com/wp-content/uploads/2021/03/59f330be85600a36ac2a6d78.jpg?resize=678%2C381&ssl=1",
        title: "SciBabe",
        description: "SciBabe is a science communication platform that offers entertaining and analytical content on health, food, and science topics. Run by chemist and writer Yvette d'Entremont."  
    },
    {
        id: COUNT++,
        url: "https://visualpde.com/",
        img: "https://visualpde.com/assets/images/heat-equation.png",
        title: "VisualPDE",
        description: "VisualPDE is a website designed to bring the world of partial differential equations to a wider audience through the use of real-time interactive web-based simulations usable on almost any device."  
    },
    {
        id: COUNT++,
        url: "https://daily.jstor.org/category/science/",
        img: "https://daily.jstor.org/wp-content/uploads/2023/05/artificial_intelligence_an_ai_generated_reading_list_1050x700.jpg",
        title: "JSTOR Daily",
        description: "JSTOR Daily is an accessible platform that connects academic research to the general public. With engaging articles, it covers history, science, culture, and society, presenting complex ideas in a digestible way. Integrated with the JSTOR digital library, readers can access full scholarly articles."  
    },
    
    {
        id: COUNT++,
        url: "https://neal.fun/space-elevator/",
        img: "/images/space_elevator.png",
        title: "Neal.fun",
        description: "Neal.fun is an entertaining website that offers a collection of interactive and creative web experiences, ranging from games and simulations to visualizations and informative tools."  
    },
    
]

export default read;
