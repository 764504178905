import React from 'react'

import {
    SimpleGrid,
    Heading,
    Box,
    HStack,
    Spacer,
} from '@chakra-ui/react';

import { Link as ChakraLink } from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';

// Watch Card
import { WatchCard } from './WatchCard';

import './index.css'

// Cards from watch categories
import watch_data from '../../Data/watch'
import category_data from '../../Data/category';

const cardsByCategory = []
let card_key = 0
watch_data.forEach((card) => {
    const { category } = card;

    category.forEach((cat) => {
        if (!cardsByCategory[cat]) {
            cardsByCategory[cat] = [];
        }
        card["key"] = card_key
        card_key++
        cardsByCategory[cat].push(card);
    });
});


const Category = () => {
    return (
        <>
            {category_data.map((category) => (
                <Box key={category.id}>
                    <HStack>
                        <Box w={1} bg={'cyan.500'} borderRadius={'md' } ml={4}>&nbsp;</Box>
                        <Heading fontSize={'1.4rem'} mb={2} pl={1} color={'gray.500'}>{category.categoryName}</Heading>
                        <Spacer />
                        <ChakraLink as={ReactRouterLink} to={`/watch/${category.categoryURL}`} pr={4} color={'teal.500'}>Show All</ChakraLink>
                    </HStack>

                    <SimpleGrid minChildWidth='166px' spacing={8} mb={12} p={4}>
                        {cardsByCategory[category.categoryName].map((card, idx) => (

                            idx < 6 &&

                            <WatchCard
                                key={card.key}
                                uniquekey={card.key}
                                image={card.img}
                                title={card.title}
                                modalTitle={card.modalTitle}
                                description={card.description}
                                videoSource={card.src}
                            >
                            </WatchCard>

                        ))}
                    </SimpleGrid>
                </Box>
            ))}


        </>
    )
}

export default Category
