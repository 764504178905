import React from 'react'
import Category from '../../Components/Category'
import { Box } from '@chakra-ui/react'
import MetaDecorator from '../../Components/Utils/MetaDecorator'
import metaData from '../../Data/metaInfo'


const Watch = () => {

  return (
    <Box maxW={'1200px'} mx={'auto'} mt={10}>
      <MetaDecorator title={metaData.watchPage.title} description={metaData.watchPage.description} />
      <Category></Category>
    </Box>
  )
}

export default Watch