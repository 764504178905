let COUNT = 0

const category = [
    {
        id: COUNT++,
        categoryName : "Top Picks",
        categoryURL: "top-picks"

    },

    {
        id: COUNT++,
        categoryName : "General Science",
        categoryURL: "general-science"

    },

    {
        id: COUNT++,
        categoryName : "Physics",
        categoryURL: "physics"
    },

    {
        id: COUNT++,
        categoryName : "Mathematics",
        categoryURL: "mathematics"

    },

    {
        id: COUNT++,
        categoryName : "Biology",
        categoryURL: "biology"
    },

    {
        id: COUNT++,
        categoryName : "Engineering",
        categoryURL: "engineering"
    },

    {
        id: COUNT++,
        categoryName : "Chemistry",
        categoryURL: "chemistry"
    },

    {
        id: COUNT++,
        categoryName : "Computer Science",
        categoryURL: "computer-science"
    },
    // {
    //     id: COUNT++,
    //     categoryName : "Shorts",
    //     categoryURL: "shorts"
    // },


]

export default category
