import React from 'react'

import {
  Stack,
  Card,
  CardBody,
  Image,
  Modal,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalOverlay,
  ModalContent,
  AspectRatio,
  useDisclosure,
  Heading,
  Text,
  Divider
} from '@chakra-ui/react'

export const WatchCard = ({ uniquekey, image, title, modalTitle, description, videoSource }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <Card key={uniquekey} borderRadius="md" overflow="hidden" onClick={onOpen} title={title} bg={'#111'} _hover={{ filter: "brightness(1)", transform: 'scale(1.005)' }} filter={"brightness(0.8)"}>
      <Image
        objectFit='cover'
        src={image}
        alt={'Oops! My Bad'}
      />
      <CardBody bg={'#111'} px={title.length < 16 && '-1'} >
        <Stack spacing='sm'>
          <Heading align='center' noOfLines={1} bg={'#111'} color={'whiteAlpha.900'} size={'sm'}>{title}</Heading>
        </Stack>
      </CardBody>

      <Modal isOpen={isOpen} onClose={onClose} size={'lg'}>
        <ModalOverlay />
        <ModalContent bg={'transparent'} color={'whiteAlpha.900'} borderWidth={'1px'} borderColor={'gray.900'}>
          <ModalHeader mr={4} >{modalTitle}</ModalHeader>
          <ModalCloseButton
            borderColor={'gray.900'}
            border="1px"
            borderRadius="50%"
            mt={2} />
          <Divider />
          <ModalBody overflowY="auto">
            <AspectRatio ratio={16 / 9}>
              <iframe width="853" height="480" src={videoSource} title={modalTitle} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </AspectRatio>

            <Stack py={4} spacing='md' >
              <Heading size={'md'} color={'whiteAlpha.900'} >{title}</Heading>
              <Text color={'whiteAlpha.600'} mt={2}>{description}</Text>
            </Stack>

          </ModalBody>
        </ModalContent>
      </Modal>

    </Card>
  )
}
