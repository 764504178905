import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Header from "./Components/Header";
import Read from "./Pages/Read";
import Watch from "./Pages/Watch";
import Originals from "./Pages/Originals";
import Listen from "./Pages/Listen";
import About from "./Pages/About";
import WatchSection from "./Components/WatchSection";
import Shorts from "./Pages/Shorts";
import Archives from "./Pages/Archives";
import Home from "./Components/Home";
import Discover from "./Pages/Discover";

import { ArchiveSection } from "./Components/ArchiveSection";

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/discover" element={<Discover />} />
          <Route path="/archives" element={<Archives />} />
          <Route path="/archives/:sectionName" element={<ArchiveSection />} />
          <Route path="/originals" element={<Originals />} />
          <Route path="/watch" element={<Watch />} />
          <Route path="/watch/:categoryName" element={<WatchSection />} />
          <Route path="/read" element={<Read />} />
          <Route path="/listen" element={<Listen />} />
          <Route path="/about" element={<About />} />
          <Route path="/shorts" element={<Shorts />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
