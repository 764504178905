import React, { useState } from 'react';
import { Waypoint } from 'react-waypoint';
import ReactPlayer from 'react-player/youtube';
import { AspectRatio, Card } from '@chakra-ui/react';

function ShortsPlayer({ videoId }) {
  const [isShown, setShown] = useState(false);
  const [isActive, setActive] = useState(false)

  const onWaypointEnter = () => {
    setShown(true)
    setActive(true)
  }

  const onWaypointLeave = () => {
    setActive(false)
  }

  return (
    <Card my="50px" borderRadius={'12px'} overflow={'hidden'} style={{ position: 'relative' }}>
      <div width="100%" height="10vh" style={{ position: 'absolute', top: '50%', translateY: '-50%', background: 'red' }}>
        <Waypoint onEnter={onWaypointEnter} onLeave={onWaypointLeave} />
      </div>
      <AspectRatio ratio={9 / 16}>
        <ReactPlayer
          className='react-player'
          url={isActive ? `https://www.youtube.com/shorts/${videoId}` : ''}
          width='auto'
          height='auto'
          playing={isActive}
          loop={isActive}
        />
      </AspectRatio>
    </Card>
  );
}

export default ShortsPlayer;
