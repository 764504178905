import React, { useRef, useState } from 'react'
import { Box } from '@chakra-ui/react'
import ShortsPlayer from '../../Components/ShortsPlayer'
import shorts from '../../Data/shorts'

import metaData from '../../Data/metaInfo'
import MetaDecorator from '../../Components/Utils/MetaDecorator'
import { Element } from 'react-scroll'

const sectionContainerStyle = {
    scrollSnapType: 'y mandatory',
    overflowY: 'scroll',
    height: '100vh',
};

const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}


const Shorts = () => {
    const randomizedPlaylist = shuffleArray(shorts);
    const containerRef = useRef(null);
    const [touchStart, setTouchStart] = useState(null);

    const handleTouchStart = (event) => {
        setTouchStart(event.touches[0].clientY);
    };

    const handleTouchMove = (event) => {
        const touchCurrent = event.touches[0].clientY;
        const scrollableContainer = containerRef.current;

        if (touchStart !== null && scrollableContainer) {
            const scrollDelta = touchStart - touchCurrent;
            scrollableContainer.scrollTop += scrollDelta;
            setTouchStart(touchCurrent);
        }
    };

    const handleTouchEnd = () => {
        setTouchStart(null);
    };

    return (
        <Box maxWidth={'380px'} mx="auto" style={sectionContainerStyle} css={{
            "&::-webkit-scrollbar": {
                width: "0px",
                background: "transparent",
            },
            scrollbarWidth: "none",
            "-ms-overflow-style": "none",
        }}
            className="video-list"
            ref={containerRef}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
        >
            <MetaDecorator title={metaData.shortsPage.title} description={metaData.shortsPage.description} />
            {randomizedPlaylist.map((video) => (
                <Element key={video.id} style={{ scrollSnapAlign: 'start' }}>
                    <ShortsPlayer key={video.id} videoId={video.url.match(/shorts\/(.+)/)[1]} />
                </Element>
            ))}
        </Box>
    )
}

export default Shorts