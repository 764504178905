let COUNT = 0

const Originals = [
    {
        id: COUNT++,
        url: "https://fuzzyklarity.substack.com/p/sub-professional-and-sub-scientific",
        img: "https://substackcdn.com/image/fetch/w_1456,c_limit,f_webp,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F3d6cdad3-35e2-4680-ac15-d11cdfca03af_1000x768.png",
        title: "Sub-professional and Sub-scientific",
        description: "1946, on the menu was ‘Bisque of Lobster’, ‘Filet Mignon’, Broiled Salmon Steak’. Cognac and Cigars. The who's who from the army, industry and academia were all there. It was a night of celebrations. A victory worth cherishing. Afterall, it wasn't every day you celebrated the birth of the world's first ever programmable, electronic, digital, general-purpose computer. Victory laps, slap on the backs, the night was meant to honour those who made it happen!"
    },
    {
        id: COUNT++,
        url: "https://fuzzyklarity.substack.com/p/the-man-who-made-it-happen",
        img: "https://substackcdn.com/image/fetch/w_1456,c_limit,f_webp,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2Fba4aa6d0-ecf7-4a34-9dc3-aac15650ad33_1007x744.png",
        title: "The man who made it happen!",
        description: "If you ever had the chance to meet him, he would insist on being called Lick. Granted, it kind of is an unusual nickname to insist upon, but then again, there was nothing usual about this young lad from St Louis, Missouri."
    },
    {
        id: COUNT++,
        url: "https://fuzzyklarity.substack.com/p/three-times-the-syllables",
        img: "https://substackcdn.com/image/fetch/w_1456,c_limit,f_webp,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F18fc0678-c302-475b-84a1-21e62f15a7c1_1017x712.png",
        title: "Three times the syllables!",
        description: " \"Robert, do you want to be rich?\" asked his friend disappointed. Robert sheepishly thought, \"Well, it helps, no?\" The phrase “Money doesn't buy happiness, it buys crazy-ass happiness” hadn't been coined yet, but our guess is even if it had, it wouldn't have affected Tim in the very least. As Robert recalled years later in an interview \"Tim’s not in it for the money.\""
    },
    {
        id: COUNT++,
        url: "https://fuzzyklarity.substack.com/p/same-same-but-different",
        img: "https://substackcdn.com/image/fetch/w_1456,c_limit,f_webp,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F421f1f84-4013-4719-857c-ea7a6f394db0_1014x769.png",
        title: "Same Same But Different",
        description: "One man with a moral fervor of the kind usually associated with priests and prophets, proclaimed proprietary software is EVIL because \"it required people to agree not to share and that made society ugly.” You may think this statement is a bit too much but not for Richard Stallman, a math wiz who went to Harvard, and in 1982 decided to write a completely non-proprietary Operating System (OS) from scratch. Given his kooky sense of humor he chose to call it GNU; where G stood for, I kid you not, GNU, a recursive acronym with N being Not and U being the most popular operating system from that period, Unix. So effectively, GNU stood for ‘GNU is Not Unix’."
    },
    {
        id: COUNT++,
        url: "https://fuzzyklarity.substack.com/p/fermats-last-theorem",
        img: "https://substackcdn.com/image/fetch/w_1456,c_limit,f_webp,q_auto:good,fl_progressive:steep/https%3A%2F%2Fbucketeer-e05bbc84-baa3-437e-9518-adb32be77984.s3.amazonaws.com%2Fpublic%2Fimages%2Fc54a3ebb-1f01-46de-9c29-259123387ea2_1005x768.png",
        title: "Fermat's Last Theorem",
        description: "\"In mathematics, guesses are called conjectures. Once a conjecture is proven, it becomes a theorem. This is a major part of what being a mathematician is all about: proving conjectures or, if we were to wax lyrical, seeking truth.\""
    },
    {
        id: COUNT++,
        url: "https://fuzzyklarity.substack.com/p/the-mother-of-all-demos",
        img: "https://substackcdn.com/image/fetch/w_1456,c_limit,f_webp,q_auto:good,fl_progressive:steep/https%3A%2F%2Fbucketeer-e05bbc84-baa3-437e-9518-adb32be77984.s3.amazonaws.com%2Fpublic%2Fimages%2Fddf87ed7-ab0b-479c-b854-875211ca2c6d_499x321.jpeg",
        title: "The Mother of all Demos!",
        description: "San Francisco, a 20-year-old boy, a radar technician, was on board a navy ship. People were waving goodbyes to their loved ones!"
    },
]

export default Originals;