import React from 'react'
import { Box, Text, Link } from '@chakra-ui/react'
import MetaDecorator from '../../Components/Utils/MetaDecorator'
import metaData from '../../Data/metaInfo'

const About = () => {
  return (
      <Box maxWidth="800px" mx="auto"  >
        <MetaDecorator title={metaData.aboutPage.title} description={metaData.aboutPage.description} />
        <Text px={4} mt="64px" color="white">
          Dear Weary Traveler,
          <br />
          <br />
          Of all the gin joints in all the towns in all the world, you walk into ours.  We are glad you are here. 
          However, we do question your choice of being here! Seriously, who reads the 'About' section anyway?
          Yet we notice you are a seeker, searching for answers to questions such as how the heck did I end up here?
          Shouldn't I be doing something more useful?

          <br />
          <br />

          Well, we can't assist you with all the answers. We are not your therapist. But true to our name,
          let us offer you some fuzzy 'klarity'.

          <br />
          <br />

          Fuzzy Klarity is an awesome and humble attempt at curating the best of science from across the internet - videos,
          websites, podcasts, and more! We don't promise to offer the latest, but we will curate the greatest for you, and if we are being
          honest, for us.

          <br />
          <br />

          As for why we're doing this? 
          We wrote a blog, but no one read it! So here we are... kidding, of course.
          With an unwavering commitment to truth, science has always stood as a relentless catalyst, propelling the frontiers of human understanding forward. 
          We are just doing our bit to promote it. 
          We assure you it has nothing to do with people not reading our blog.

          <br />
          <br />

          Regardless, in case you have any cool resources you would like us to feature, we are all ears, eyes, nose, skin, and heart. Do
          drop us a note at <Link href="mailto:lookthisisawesome@fuzzyklarity.com" color="blue.500">lookthisisawesome@fuzzyklarity.com</Link>.

          <br />
          <br />
        </Text >
      </Box>
  )
}

export default About