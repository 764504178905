import React from 'react'

import {
  Stack,
  Card,
  CardBody,
  Image,
  AspectRatio,
  Heading,
  Text,
  Link
} from '@chakra-ui/react'

import moment from 'moment';


export const ArchiveCard = ({ url, img, title, description, date }) => {
  return (
    <Card
      bg={"transparent"}
      _hover={{ filter: "brightness(1)", transform: "scale(1.005)" }}
      filter={"brightness(0.8)"}
      position={"relative"}
      maxW='sm'
    >
      <Link href={url} isExternal style={{ textDecoration: "none" }}>
        <CardBody textAlign="left">
          <Card display={"flex"} top="5" position={"absolute"} zIndex="100" style={{border: 'none', backgroundColor: 'transparent'}}>
            <Text
              color={"white"}
              minH="30px"
              p="0.5rem"
              style={{border: 'none', backgroundColor: '#000000cc', borderBottomRightRadius: '12px'}}
            >
              {moment(date).format("MMMM Do YYYY")}
            </Text>
          </Card>
          <AspectRatio overflow={"hidden"} borderRadius={"md"}>
            <Image objectFit={"cover"} src={img} alt={description} />
          </AspectRatio>

          <Stack mt="6" spacing="3">
            <Heading size="md" color={"#eee"}>
              {title}
            </Heading>
            <Text fontSize={"12px"} color={"#aaa"} noOfLines={[2, 3]}>
              {description}
            </Text>
          </Stack>
        </CardBody>
      </Link>
    </Card>
  );
}