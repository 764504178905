import React from 'react'
import { Helmet } from 'react-helmet'

const hostname = "https://www.fuzzyklarity.com"

const MetaDecorator = (props) => {
    return (
        <Helmet>
            <title> {props.title} </title>
            <meta name="description" content={props.description} data-react-helmet="true" />
            <meta property="og:title" content={props.title} />
            <meta property="og:description" content={props.description} />
            <meta property="og:url" content={hostname + window.location.pathname + window.location.search} />
        </Helmet>
    )
}


export default MetaDecorator