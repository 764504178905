const Category = {
  topPicks: "Top Picks",
  generalScience: "General Science",
  physics: "Physics",
  mathematics: "Mathematics",
  biology: "Biology",
  engineering: "Engineering",
  chemistry: "Chemistry",
  computerScience: "Computer Science",
  shorts: "Shorts",
};

let COUNT = 0;

const watch = [
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/DOWDNBu9DkU",
    img: "https://yt3.googleusercontent.com/ytc/AL5GRJV8nNBYEmOJznUS8Lz6bZfOlZU4G6rM1zNbZyK2=s176-c-k-c0x00ffffff-no-rj-mo",
    title: "Mark Rober",
    modalTitle: "Amazing Invention- This Drone Will Change Everything",
    description:
      "Former NASA engineer. Current CrunchLabs founder and friend of science.",
    category: [Category.topPicks],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/HeQX2HjkcNo",
    img: "https://yt3.googleusercontent.com/ytc/AL5GRJVCEkKb1MGQDxzHm958YTnpEl1V0utKdZ2knmSCyA=s176-c-k-c0x00ffffff-no-rj",
    title: "Veritasium",
    modalTitle: "Math's Fundamental Flaw",
    description:
      "An element of truth - videos about science, education, and anything else I find interesting.",
    category: [Category.topPicks],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/AwbZaTjXo-s",
    img: "https://yt3.googleusercontent.com/ytc/AL5GRJWfFUxrQ7OANLqsA_XmFKrgshFuI5LR4vTN2yQ_Ag=s176-c-k-c0x00ffffff-no-rj",
    title: "Up and Atom",
    modalTitle: "Why it took 379 pages to prove 1+1=2",
    description:
      "Making hard stuff less hard. Math, physics, and computer science.",
    category: [Category.topPicks],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/zlm1aajH6gY",
    img: "https://yt3.googleusercontent.com/1neiHlxKYbiSRqxuSEznxedeeYTTIyxwo1vKXxKTZwwgwBenm3dQC_rXHSo9udIGt2hGsQ4j=s176-c-k-c0x00ffffff-no-rj",
    title: "Quanta Magazine",
    modalTitle: "The Riemann Hypothesis, Explained",
    description:
      "Explore mind-bending developments in basic science and math research. Quanta Magazine is an award-winning, editorially independent magazine published by the Simons Foundation.",
    category: [Category.topPicks],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/QmOF0crdyRU",
    img: "https://yt3.googleusercontent.com/5ONImZvpa9_hYK12Xek2E2JLzRc732DWsZMX2F-AZ1cTutTQLBuAmcEtFwrCgypqJncl5HrV2w=s176-c-k-c0x00ffffff-no-rj",
    title: "Andrew Huberman",
    modalTitle:
      "Controlling Your Dopamine For Motivation, Focus & Satisfaction",
    description:
      "The Huberman podcast is hosted by Dr. Andrew Huberman, a neuroscientist and tenured professor of neurobiology and ophthalmology at Stanford School of Medicine. The podcast discusses neuroscience and science-based tools, including how our brain and its connections with the organs of our body control our perceptions, our behaviors, and our health, as well as existing and emerging tools for measuring and changing how our nervous system works.",
    category: [Category.topPicks],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/eCDabfJXPN0",
    img: "https://yt3.googleusercontent.com/gKRoi_qYBWKJYIDdych5WF1R-3j0CSO7KaeCCpU3lwsWBHNij17ZHg5q_EfjFjArsBf18wtU=s176-c-k-c0x00ffffff-no-rj",
    title: "Microcosmos",
    modalTitle: "The Collotheca Doesn’t Mind Eating Its Own Babies",
    description:
      "Take a dive into the tiny, unseen world that surrounds us! With music by Andrew Huang, footage from James Weiss, and narration by Hank Green, we want to take you on a fascinating, reflective journey through the microcosmos.",
    category: [Category.topPicks, Category.biology],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/SzJ46YA_RaA",
    img: "https://yt3.googleusercontent.com/cNEy6sWctOxzoQcc5DiNOQQMq4Qc7m8JaO-CVhqKsuVFEGRrh7QBsOmijWNHP9_LYPpoyhyLFyU=s176-c-k-c0x00ffffff-no-rj",
    title: "Domain of Science",
    modalTitle: "Map of Computer Science",
    description:
      "Made and hosted by Dominic Walliman, PhD, experimental quantum physicist and author.",
    category: [Category.topPicks],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/OydqR_7_DjI",
    img: "https://yt3.googleusercontent.com/ytc/AL5GRJVvup3pchjMORTXpYRcqVHrs6l6XSHqtWZJQXZ5=s176-c-k-c0x00ffffff-no-rj",
    title: "Minute Physics",
    modalTitle: "How to See Without Glasses",
    description:
      "Simply put: cool physics and other sweet science. \"If you can't explain it simply, you don't understand it well enough.\" ~ Rutherford via Einstein? (wikiquote)",
    category: [Category.topPicks, Category.physics],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/_27eD49ePQE",
    img: "https://yt3.googleusercontent.com/ytc/AL5GRJWjS7at2j0n5yn5dsfYFe5x_V8QeN66ppWjzChpoQ=s176-c-k-c0x00ffffff-no-rj",
    title: "Kurzgesagt",
    modalTitle: "Kurzgesagt Channel Trailer",
    description:
      "Animation videos explaining things with optimistic nihilism since 12,013. We’re a team of illustrators, animators, number crunchers, and one dog who aim to spark curiosity about science and the world we live in. To us nothing is boring if you tell a good story.",
    category: [Category.topPicks],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/PCuyCJocJWg",
    img: "https://yt3.googleusercontent.com/ytc/AL5GRJU6bZbLok6JYYwxj4u6-SXlB6yGdfTODPtwVUwP2A=s176-c-k-c0x00ffffff-no-rj",
    title: "Physics Girl",
    modalTitle:
      "Why This Stuff Costs $2700 Trillion Per Gram - Antimatter at CERN",
    description:
      "Physics Girl is a YouTube channel created by Dianna Cowern that adventures into the physical sciences with experiments, demonstrations, and cool new discoveries. Physics Girl has videos for every atom and eve.",
    category: [Category.topPicks, Category.physics],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/iW_LkYiuTKE",
    img: "https://yt3.googleusercontent.com/ytc/AL5GRJVgDSmde7jk_ApxeCTrT5Ox1lsBcxisjPVAE9z_7g=s176-c-k-c0x00ffffff-no-rj",
    title: "Numberphile",
    modalTitle: "The problem in Good Will Hunting",
    description:
      "Numberphile is produced by video journalist Brady Haran. The stars of the show include mathematicians and other guests from around the world. Topics range from the sublime to the ridiculous… from historic discoveries to latest breakthroughs.",
    category: [Category.topPicks, Category.mathematics],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/WUvTyaaNkzM",
    img: "https://yt3.googleusercontent.com/ytc/AL5GRJWkvJbKMtObkrt4bHRDDWgOnouAlx1wEMOzR495nA=s176-c-k-c0x00ffffff-no-rj",
    title: "3Blue1Brown",
    modalTitle: "The essence of calculus",
    description:
      "3Blue1Brown, by Grant Sanderson, is some combination of math and entertainment, depending on your disposition. The goal is for explanations to be driven by animations and for difficult problems to be made simple with changes in perspective.",
    category: [Category.topPicks, Category.mathematics],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/-5wpm-gesOY",
    img: "https://yt3.googleusercontent.com/ytc/AL5GRJWn6e2iLefhHdi7a7j0R9CfAW-uoWv_Jkf_AS6r9Q=s176-c-k-c0x00ffffff-no-rj",
    title: "Computerphile",
    modalTitle: "The Problem with Time & Timezones",
    description:
      "Videos all about computers and computer stuff. Sister channel of Numberphile.",
    category: [Category.topPicks, Category.computerScience],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/PriwCi6SzLo",
    img: "https://yt3.googleusercontent.com/ytc/AL5GRJX-xsmjAovFMnLBIQDsn2IHQLH11y9NNBYVYAWOKg=s176-c-k-c0x00ffffff-no-rj",
    title: "Medlife Crisis",
    modalTitle: "Should Knowledge Be Free?",
    description:
      "I'm a consultant cardiologist and university researcher that makes science videos and bad jokes. Offbeat topics you won't find elsewhere, enriched with a government-mandated dose of humour. There's a lot of bad science on YouTube, especially medicine, with quacks and clowns peddling garbage.  For people interested in science, comedy, and mostly useless biological trivia, subscribing to this channel was found to be non-inferior to placebo. If that isn't a ringing endorsement I don't know what is.",
    category: [Category.topPicks],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/2zwZsjlJ-G4",
    img: "https://yt3.ggpht.com/oRpiXWb3Luj7NZ-18koGepJvSgCMfh7m3NhLh-tuDbRVptqQVh-mRHehAeu9fDzVzZ0TpD0TfdM=s176-c-k-c0x00ffffff-no-rj-mo",
    title: "Tibees",
    modalTitle: "Einstein's grades 👀",
    description:
      "Hi, I'm Toby. On my channel you can expect to see videos about physics, math, astronomy, and the history of science.",
    category: [Category.topPicks],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/IJhgZBn-LHg",
    img: "https://yt3.googleusercontent.com/ytc/AL5GRJWvTe2XC80z6_cZh5GZoYhRWaONosagXi6OnhiZGA=s176-c-k-c0x00ffffff-no-rj",
    title: "Vsauce",
    modalTitle: "How Earth Moves",
    description:
      "Vsauce is part of a network of the top science and education channels on YouTube, comprised of Vsauce1, Vsauce2, Vsauce3, and Ding! They focus on hyper-curiosity about the world and look at everything with fresh, excited eyes. If you like to learn - you’re in the right place!",
    category: [Category.topPicks],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/7r83N3c2kPw",
    img: "https://yt3.googleusercontent.com/99wepc_FTSN0n_GbR-FlFANyxed7TsbE8WxKIDWftdxssZlYo1-gW1CRD7cPgOzThMM8m4W8=s176-c-k-c0x00ffffff-no-rj",
    title: "The Coding Train",
    modalTitle: "What was Coding like 40 years ago?",
    description:
      "All aboard! The Coding Train is on its way with creative coding video tutorials on subjects ranging from the basics of programming languages like JavaScript to algorithmic art, machine learning, simulation, generative poetry, and more. Choo choo!",
    category: [Category.topPicks, Category.computerScience],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/3g246c6Bv58",
    img: "https://yt3.googleusercontent.com/OTeVrhBf3byA2ofSUO1vbhzbB0XAmBpm6Rgm85xO6OUVmW3PoPQ0Siy5UJbuRJC16PGImImvm34=s176-c-k-c0x00ffffff-no-rj",
    title: "Be Smart",
    modalTitle: "Why Is Blue So Rare In Nature?",
    description:
      "We give you deep answers to simple questions about science and the rest of the universe.",
    category: [Category.generalScience],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/DAUl6upA3q4",
    img: "https://yt3.googleusercontent.com/_BsIQ_sNpOtu2c9BNGSfGFpNTSwLPTzJpL774Q1v85Sc-uitgp8ouwljeN_6YtDlULLaGFY_6nM=s176-c-k-c0x00ffffff-no-rj",
    title: "Steve Mould",
    modalTitle: "Self-Healing Material",
    description: "I'm Steve Mould. I make videos about science.",
    category: [Category.generalScience],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/ZPw9dSV6y2c",
    img: "https://yt3.googleusercontent.com/ytc/AL5GRJWaP6hBHZPZZbo0IZD6CEFS6SU-7kFdnJMpUb7JUA=s176-c-k-c0x00ffffff-no-rj",
    title: "The Action Lab",
    modalTitle: "How I Made an Ant Think It Was Dead—The Zombie Ant Experiment",
    description:
      "The Action Lab is a channel dedicated to performing exciting experiments and answering questions you never thought to ask! I'll put things in my hydraulic press, vacuum chamber, and perform countless other experiments just to watch cool phenomenon. I experiment on the world and objects around me. You'll be so amazed that you'll forget you are even learning anything! Please enjoy as I continually answer the \"what would happen if...\" questions you have.",
    category: [Category.generalScience],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/_FlV6pgwlrk",
    img: "https://yt3.googleusercontent.com/ytc/AL5GRJXjajmCUGCIz4XvtF-ZjRT9VqQHFpNrIAhGYkqE-w=s176-c-k-c0x00ffffff-no-rj",
    title: "SmarterEveryDay",
    modalTitle: "You Won't Believe Your Eyes!",
    description:
      "Hey, It's me, Destin and welcome to SmarterEveryDay. I'm a Husband, a Father, and an Aerospace Engineer. Several years ago, I made a video to show my friends how to light a bonfire with rockets. They enjoyed my teaching style so I kept making videos. Explore the world using science. That's pretty much all there is to it. Watch 2 videos. If you learn something awesome, feel free to subscribe to the YouTube channel.",
    category: [Category.generalScience],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/0g8lANs6zpQ",
    img: "https://yt3.googleusercontent.com/ytc/AL5GRJVUUMCY8xSA9lqdORSQCHP40FUbDpMBnAU2ZAT9ew=s176-c-k-c0x00ffffff-no-rj",
    title: "The Royal Institution",
    modalTitle: "The Magic of Chemistry - with Andrew Szydlo",
    description:
      "Videos to deepen your interest and passion for science. Explosive short films, full length talks from the world’s leading scientists and writers, and videos to challenge the way you look at the world.",
    category: [Category.generalScience],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/Qe5WT22-AO8",
    img: "https://yt3.googleusercontent.com/ytc/AL5GRJUvWK-cxYbrw0ljSsn6H7129uf4ey7Cm4lcbJ9VjQ=s176-c-k-c0x00ffffff-no-rj",
    title: "BobbyBroccoli",
    modalTitle: "The man who tried to fake an element",
    description:
      "Howdy! I do deep dive broccumentaries into science scandals and controversies.Currently, I animate in Blender, and previously dabbled in Google Earth for a bit. Studied engineering physics and photonics in school.",
    category: [Category.generalScience],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/GwzN5YwMzv0",
    img: "https://yt3.googleusercontent.com/rN0sMKGXLGcG-gWIYKnbMyKCgEaIeY62P1vaslUPO_3QObfnwBZNG2hyTmysFYRayXt0LKHJ=s176-c-k-c0x00ffffff-no-rj",
    title: "Sabine Hossenfelder",
    modalTitle: "Does the Past Still Exist?",
    description:
      'Science and technology updates and summaries. No hype, no spin, no tip-toeing around inconvenient truths. News each Wednesday, new topical video each Saturday. Sabine Hossenfelder has a PhD in physics. She is author of the books "Lost in Math: How Beauty Leads Physics Astray" (Basic Books, 2018) and "Existential Physics: A Scientist\'s Guide to Life\'s Biggest Questions" (Viking, 2022).',
    category: [Category.physics],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/0Uqf71muwWc",
    img: "https://yt3.googleusercontent.com/ytc/AL5GRJVTBIIGqCeKVgA0cU3kAmKygMoVv2m2egnqPgdW=s176-c-k-c0x00ffffff-no-rj",
    title: "Sixty Symbols",
    modalTitle: "Unlocking a car with your Brain",
    description: "Cool videos about physics and astronomy.",
    category: [Category.physics],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/OV9MnAZLmMQ",
    img: "https://yt3.googleusercontent.com/ytc/AL5GRJWJ5EwknbEGTAP0D33DdEYZZXBCq91adzl1rgWpSA=s176-c-k-c0x00ffffff-no-rj",
    title: "PBS Space Time",
    modalTitle: "What’s Your Brain’s Role in Creating Space & Time?",
    description:
      "Space Time explores the outer reaches of space, the craziness of astrophysics, the possibilities of sci-fi, and anything else you can think of beyond Planet Earth with our astrophysicist host: Matthew O’Dowd.",
    category: [Category.physics],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/d4H1wEE5IdI",
    img: "https://yt3.googleusercontent.com/ytc/AL5GRJUV3xkk1YTI1Q0LeanurjweY52XZXrYEVjR9MZquA=s176-c-k-c0x00ffffff-no-rj",
    title: "Astrum",
    modalTitle: "What James Webb Saw Near the Edge of the Universe",
    description:
      "Find space interesting but are put off by its complexities? Tired of mainstream sensationalism? Are you a space enthusiast who wants to watch concise and interesting space documentaries? This is the channel for you.",
    category: [Category.physics],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/-dhHrg-KbJ0",
    img: "https://yt3.googleusercontent.com/ytc/AL5GRJU15av58alazKD2kPprj8XPnCdh7UsFpYqE3oqB7Q=s176-c-k-c0x00ffffff-no-rj",
    title: "Mathologer",
    modalTitle: "e to the pi i for dummies",
    description:
      "Enter the world of the Mathologer for really accessible explanations of hard and beautiful math(s). In real life, the Mathologer is a math(s) professor at Monash University in Melbourne, Australia and goes by the name of Burkard Polster.",
    category: [Category.mathematics],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/3GJUM6pCpew",
    img: "https://yt3.googleusercontent.com/ytc/AL5GRJV9G9Iq7GcTy1m3DdB1GDkaNw_jotno9KHohihHhA=s176-c-k-c0x00ffffff-no-rj",
    title: "Stand-up Maths",
    modalTitle: "Why is TV 29.97 frames per second?",
    description:
      "I do mathematics and stand-up. Sometimes simultaneously. Occasionally while being filmed.(It's quite the Venn diagram.)",
    category: [Category.mathematics],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/cmskjWp6Dpc",
    img: "https://yt3.googleusercontent.com/ytc/AL5GRJUpord0EMirP7W4T6l-za01hPwNKnheyfz-JMFJ=s176-c-k-c0x00ffffff-no-rj",
    title: "Vihart",
    modalTitle: "2023 is the 13th of these which is so many",
    description: "Vi Hart likes triangles! AND APPLES!",
    category: [Category.mathematics],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/pTnEG_WGd2Q",
    img: "https://yt3.googleusercontent.com/ytc/AL5GRJXL9Nb9xZAs9MY4pFWtnLQ-KrdYQbr_qGsxCA1xGg=s176-c-k-c0x00ffffff-no-rj",
    title: "The Math Sorcerer",
    modalTitle: "Learn Mathematics from START to FINISH",
    description:
      "Math videos and all things math related. I also make videos on other things too.",
    category: [Category.mathematics],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/_1LdMWlNYS4",
    img: "https://yt3.googleusercontent.com/ytc/AL5GRJWN-mT_Oj_bH735wvUJho_nvv5wvQpE0XZQeh6bbA=s176-c-k-c0x00ffffff-no-rj",
    title: "PBS Eons",
    modalTitle: "That Time It Rained for Two Million Years",
    description:
      "Join hosts Kallie Moore, Michelle Barboza-Ramirez, and Blake de Pastino as they take you on a journey through the history of life on Earth. From the dawn of life in the Archaean Eon through the Mesozoic Era - the so-called “Age of Dinosaurs” -- right up to the end of the most recent Ice Age.",
    category: [Category.biology],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/4tlCDcqZYgk",
    img: "https://yt3.googleusercontent.com/ytc/AL5GRJVG83u4Q2qeuRuFh4Uwb3Ten5gA0xpG632wedbF=s176-c-k-c0x00ffffff-no-rj",
    title: "Institute of Human Anatomy",
    modalTitle: "What Sugar Really Does to the Body",
    description:
      "Our goal is simple -- teach as many people as possible about the one thing they carry with themselves 24/7, for the entirety of their lives -- their body.",
    category: [Category.biology],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/QjPInsEYDLs",
    img: "https://yt3.googleusercontent.com/ytc/AL5GRJUeWysLbOmU75MqeamF9mp2zW8NDkLr8tblhE_OZg=s176-c-k-c0x00ffffff-no-rj",
    title: "Deep Look",
    modalTitle: "These Giant Leaf Insects Will Sway Your Heart",
    description:
      "DEEP LOOK is a science video series that explores big science by going very, very small, from KQED and PBS Digital Studios.",
    category: [Category.biology],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/z3H6fJ3t-Cs",
    img: "https://yt3.googleusercontent.com/ytc/AL5GRJWIvqk19ajy_TZFTzDySDmrHosoi75IS9U1DAbQ=s176-c-k-c0x00ffffff-no-rj",
    title: "Bizarre Beasts",
    modalTitle: "This Frog Is Bad At Basically Everything",
    description:
      "What makes a weird animal weird? Once a month, hosts Hank Green and Sarah Suta introduce you to a new bizarre beast and explore the many answers to this question.",
    category: [Category.biology],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/7wKu13wmHog",
    img: "https://yt3.ggpht.com/hUrDI5Q0fIt4sWIfNkDYkXkg3bmSfw2xgJvKYq5NKmlqvUDYmJzagMaPMkArbZyD4CXP1C08=s176-c-k-c0x00ffffff-no-rj-mo",
    title: "BBC Earth",
    modalTitle: "Kung Fu Mantis Vs Jumping Spider",
    description:
      "BBC Earth brings you face to face with heart-pounding action, mind-blowing ideas, and the sheer wonder of being part of this amazing planet we call home.",
    category: [Category.biology],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/7J7X7aZvMXQ",
    img: "https://yt3.googleusercontent.com/4toKBJKPsAfwxy2xfr_EQJ7CuHpLvZa4eux6T2TAf8EU25uH_G9gbUA9djqaCrTjkIESnPjzww=s176-c-k-c0x00ffffff-no-rj",
    title: "Branch Education",
    modalTitle: "How does Computer Memory Work?",
    description:
      "The world is fascinating. Rockets to space are landing themselves. Cars are on the verge of driving themselves. Microchips are continuing to become smaller and faster. These innovations take billions or even trillions of person-hours to develop and another trillion hours to debug. However, understanding the in-depth details of these complex systems requires thousands of hours of research, curriculum building, and content streamlining.Our objective is to make engaging content that answers each facet of these complex systems.Currently, we are making and evolving our video content to be as engaging as possible.Founder of Branch Education: Theodore J Tablante",
    category: [Category.engineering],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/wvJc9CZcvBc",
    img: "https://yt3.googleusercontent.com/ytc/AL5GRJVcOL7J1pK3PpKdeiW3dqPkSHwH6eC-DnOOWGK7=s176-c-k-c0x00ffffff-no-rj",
    title: "Ben Eater",
    modalTitle:
      "Learn how computers add numbers and build a 4 bit adder circuit",
    description:
      "Subscribe to see tutorial-style videos about electronics, computer architecture,networking, and various other technical subjects.",
    category: [Category.engineering],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/0MwMkBET_5I",
    img: "https://yt3.googleusercontent.com/ytc/AL5GRJU_JDP41mbgqoZIjyrbNepXKoQcudKhkAljg8lRaQ=s176-c-k-c0x00ffffff-no-rj",
    title: "engineerguy",
    modalTitle: "Fiber optic cables: How they work",
    description: "Bill Hammack explores the world of engineering",
    category: [Category.engineering],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/pH7VfJDq7f4",
    img: "https://yt3.googleusercontent.com/ytc/AL5GRJVNuipMI5C6TGHcFexcSi1xUkUptEKboFY2Kybtjw=s176-c-k-c0x00ffffff-no-rj",
    title: "Practical Engineering",
    modalTitle: "Why Bridges Move... ",
    description:
      "Practical Engineering is all about infrastructure and the human-made world around us.It is hosted, written, and produced by civil engineer, Grady Hillhouse..",
    category: [Category.engineering],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/5KHSz9Gi-II",
    img: "https://yt3.googleusercontent.com/ytc/AL5GRJVrQq-9FSRotQlHJZsvjZ75LJyR4qu9rnm5eAURD2M=s176-c-k-c0x00ffffff-no-rj",
    title: "Techmoan",
    modalTitle: "The most expensive music format (in the world)",
    description:
      "Enjoying the best and worst of new and old technology. Consumer Tech - by a Tech Consumer.",
    category: [Category.engineering],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/zdkyGDqU7xA",
    img: "https://yt3.googleusercontent.com/ytc/AL5GRJU8ug0Y2R_WWaWDpuEBJisDuTx9UgAqq8tVmGV4=s176-c-k-c0x00ffffff-no-rj",
    title: "Tech Ingredients",
    modalTitle: "Fantastic DIY Speakers for less than $30!",
    description:
      "We started off as a small computer business, but we began making laser videos on YouTube back in 2013. We've been growing rapidly since we've expanded our videos to include all kinds of interesting technologies.",
    category: [Category.engineering],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/d49TzVF1gmY",
    img: "https://yt3.googleusercontent.com/ytc/AL5GRJW58AOm-PPtQy0u2UlK7de8UZLWQExx3wOsy74DnA=s176-c-k-c0x00ffffff-no-rj",
    title: "Periodic srcs",
    modalTitle: "Exploding Hydrogen Bubbles",
    description:
      "Your ultimate channel for all things chemistry. A video about each element on the periodic table. And we upload new videos every week about science news, interesting molecules, and other stuff from the world of chemistry.",
    category: [Category.chemistry],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/vHuFizITMdA",
    img: "https://yt3.googleusercontent.com/ytc/AGIKgqNteHNAHEfbowpDliOTM5nUqghOaQdwPeCZibCQXA=s176-c-k-c0x00ffffff-no-rj-mo",
    title: "NileRed",
    modalTitle: "Turning cotton balls into cotton candy",
    description:
      "Capturing the natural beauty of chemistry, I find that chemistry is often taught poorly or without a purpose. Because of this, people tend to lose interest and sometimes even start to hate it. In each video that I make, I try to balance theory with purpose. My goal is to capture the natural beauty of chemistry in fun and interesting ways.",
    category: [Category.chemistry],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/HBz9pOZOS4k",
    img: "https://yt3.googleusercontent.com/ytc/AL5GRJVZundP7Wx7zV8rZ49-m4fRcNaO16OkzxAtRMG6YQ=s176-c-k-c0x00ffffff-no-rj",
    title: "NileBlue",
    modalTitle: "Making food that lasts forever",
    description: "Nigel goofing around in the lab.",
    category: [Category.chemistry],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/ytxx95g-kiA",
    img: "https://yt3.googleusercontent.com/ytc/AL5GRJU2rZQacfPCtuAsG41AeXwybCJvPWPC8ub606eIow=s176-c-k-c0x00ffffff-no-rj",
    title: "Thoisoi2 - Chemical Experiments!",
    modalTitle: "Cesium - The most ACTIVE metal on EARTH!",
    description:
      "On this channel, you will find some interesting chemical reactions and many amazing stuff!",
    category: [Category.chemistry],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/hZbnLDvDbFo",
    img: "https://yt3.googleusercontent.com/ytc/AL5GRJW5nXDpR9VKXdq0SP-1phMF7oeRrQCRRQltUJEvOg=s176-c-k-c0x00ffffff-no-rj",
    title: "NightHawkInLight",
    modalTitle: "6 Unauthorized Uses for a Medical Oxygen Concentrator",
    description:
      "Thanks for stopping by! My name is Ben. I turn boring things into awesome things.I've been creating and filming projects and science experiments since 2006 and have had the amazing privilege of seeing them bring happiness to millions of people.",
    category: [Category.chemistry],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/5IFsaBBav78",
    img: "https://yt3.ggpht.com/5ATfT7hT2wcQlqYRASbjD7lleFA-rpj79_U0jP9cLTgqbeU6Ul5gx2a94FnyU_DNrJhzKNIzig=s176-c-k-c0x00ffffff-no-rj-mo",
    title: "That Chemist",
    modalTitle: "Which Nerve Agent is the Most Evil?",
    description:
      "Hey, welcome to my channel! I'm creating videos about science, but mostly chemistry.I hope that my videos will provide you with some new insight, or at the very least I hope that you will enjoy my anecdotes about organic chemistry. As a research chemist, I’ve always found it enjoyable to share my experiences in the lab with other chemistry enthusiasts.",
    category: [Category.chemistry],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/I0-izyq6q5s",
    img: "https://yt3.googleusercontent.com/ytc/AL5GRJWKmhpTwria9ebNnDHBfGtHXov3yR5sdMwxkqgMqw=s176-c-k-c0x00ffffff-no-rj",
    title: "Sebastian Lague",
    modalTitle: "How Do Computers Remember?",
    description:
      "Hello! on this channel, I explore how to create stuff out of code. I hope you find some of it interesting!",
    category: [Category.computerScience],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/EFUYNoFRHQI",
    img: "https://yt3.ggpht.com/Ndf7d4uuJXm3Baoh2udAFIFLX4VVT_iD0FZESn8zfnCPtGYhq5uASIdoRRM8RJ5gXHPUrxX1=s176-c-k-c0x00ffffff-no-rj-mo",
    title: "Reducible",
    modalTitle: "How PNG Works: Compromising Speed for Quality",
    description:
      "This channel is all about animating computer science concepts in a fun, interactive, and intuitive manner.",
    category: [Category.computerScience],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/w6E7aQnA4Ws",
    img: "https://yt3.googleusercontent.com/_XHlAXJYXCSz7sFXGsMxYRSz5QQBh5mo-Sl0lAPXZIFch4n7j6rO4ZsWnrxzbzbrWPds14fdTw=s176-c-k-c0x00ffffff-no-rj",
    title: "Spanning Tree",
    modalTitle: "A Computer Built With Dominos",
    description:
      "Spanning Tree is an educational video series about computer science and mathematics.Spanning Tree is created by Brian Yu.",
    category: [Category.computerScience],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/OY41QYPI8cw",
    img: "https://yt3.googleusercontent.com/ytc/AGIKgqPVSMETFgg8m2Y8bC1N3qWJ5RMxeN4bmnL66J_e=s176-c-k-c0x00ffffff-no-rj",
    title: "Undefined Behavior",
    modalTitle: "P vs. NP - An Introduction",
    description: "Videos on computer science and math",
    category: [Category.computerScience],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/U4rGnPeNaVg",
    img: "https://yt3.googleusercontent.com/73wf0XeWUcTHhBRN8IHwx5uoEf0Vnsv9G4fti7LtWP3NOcIs4ZmiQnw37oxfrzrzcPstkfChiQ=s176-c-k-c0x00ffffff-no-rj",
    title: "Natural Habitat Shorts",
    modalTitle: "Many species of shrews are venomous",
    description: "",
    channelLink: "https://www.youtube.com/@NaturalHabitatShorts",
    category: [Category.shorts],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/Tx6xxPa2Drw",
    img: "https://yt3.googleusercontent.com/N4uMLqu7llHcGnVpu0K5aBMQMGFA9z1tHQ4V0H4j7u2_z3ki6JQpOOP4EscX-YeSWmDgjPD1cA=s176-c-k-c0x00ffffff-no-rj",
    title: "Unnecessary Inventions",
    modalTitle: "This invention makes getting out of bed so much easier.",
    description:
      "Unnecessary Inventions is the brainchild of the self taught inventor and evil genius Matty Benedetto. Over the past three years, he has designed and fully prototyped over 350 new inventions that solve problems that don't exist.",
    category: [Category.shorts],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/Ffpu7uyVgrk",
    img: "https://yt3.googleusercontent.com/bjCbTNoTA9qtRrynJGNz1-ipMQwZeKqZ9wWVmIp-tX0vB2ZOGjLJ8--hYZjlPfKdUkGR9m_xTg=s176-c-k-c0x00ffffff-no-rj",
    title: "Dr Karan",
    modalTitle: "Doctor Exposes Toothpaste Advertising Scam!",
    description: "Let me “edu-tain” with science, health, & medicine!",
    category: [Category.shorts],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/bHX6udIKsK8",
    img: "https://yt3.googleusercontent.com/9Okai3ls8y43BAa3klfbJanWDcm47OodtS9bqoMwdOW1MnJ_6WG83FmS2admLBHlZXATZ7fz=s176-c-k-c0x00ffffff-no-rj",
    title: "Chemteacherphil",
    modalTitle: "Breaking Bad Sugar Glass!",
    description:
      "So many people have had a negative experience learning chemistry, and I hope to provide a way for people to have a positive and engaging experience learning science through the videos I create. As a high school chemistry teacher, I try to make videos that reveal how chemistry applies to real life. This ends up taking many forms; sometimes explaining the chemistry behind how things work, or a historical perspective, always through the lens of science.",
    category: [Category.shorts],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/JDEniX8NAZQ",
    img: "https://yt3.googleusercontent.com/ytc/AGIKgqMxnsHo9a9nle40v5v5FnYzuUcbFKBH9Z5wcfKHwA=s176-c-k-c0x00ffffff-no-rj",
    title: "Action Lab Shorts",
    modalTitle: "Triangle Wheels Are The Future",
    description:
      "On this channel, I give you short science tidbits that are awesome!",
    category: [Category.shorts],
  },

  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/S42uQzogM5o",
    img: "https://yt3.googleusercontent.com/rzEzQ5SZYWBuLwCoxS1jAFS66ZkBJcy8IXlyMD-Qd40n0iGVnFvhDsa8MM56YRVnY5knx6Xg2w=s176-c-k-c0x00ffffff-no-rj",
    title: "Mathematical Visual Proofs",
    modalTitle: "How many radii fit around a circle?",
    description:
      'I animate and provide some explanation for classic and newer "proofs without words," which are typically diagrams without any words that indicate how a theorem could be proved.',
    category: [Category.shorts],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/9y243yQEIm0",
    img: "https://yt3.googleusercontent.com/0GVFDL5wCLgnL3C15-YxWiNy_3bR8-bzjt-4fpJJNGBcgXiAqkM5-mIMDYAbGZqHu4Kcjkpy=s176-c-k-c0x00ffffff-no-rj",
    title: "mryeester",
    modalTitle: "why do CPUs use clocks?",
    description: "I tinker with tech!",
    channelLink: "https://www.youtube.com/@mryeester",
    category: [Category.shorts],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/gXhj3Cp59YE",
    img: "https://yt3.googleusercontent.com/QYr8VgYcdA4FBDExWgAqUHCrBq0dgJvPPy-S4hUa_H00wVlIej_otvyHMLmRFYz5FqH6zPlrvg=s176-c-k-c0x00ffffff-no-rj",
    title: "SciShow",
    modalTitle: "You're wrong about the inside of the Earth.",
    description:
      "SciShow explores the unexpected. We delve into the scientific subjects that defy our expectations and make us even more curious!",
    channelLink: "https://www.youtube.com/@SciShow",
    category: [Category.shorts],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/4kXfACSZAUI",
    img: "https://yt3.googleusercontent.com/7AvcX3c3L5y4giZILibgtpM8vUlKxhXRswX0DIIcaii5OYCc-8Xqp10jrPP5Fdr77C9GHf0UIQ=s176-c-k-c0x00ffffff-no-rj",
    title: "flemdogscience",
    modalTitle: "Best Diffusion Experiment Ever (maybe)...Full",
    description:
      "What is more incredible than Earth?! Let's do a bazillion experiments on it to learn as much as we can. Science is communal so I want to learn about everything I can, with you! Tell me all about the experiments you want to learn about too!",
    channelLink: "https://www.youtube.com/@flemdogscience",
    category: [Category.shorts],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/Rrp3MNXVw8Q",
    img: "https://yt3.googleusercontent.com/rxWlblrNs6725Ly6xhY8wDHhwqI_O3F9NCoQkWbyXY02aaxJxDxE0ANzgrjejL5aitzKst_g=s176-c-k-c0x00ffffff-no-rj",
    title: "JBV Creative",
    modalTitle: "From 2D Logo to 3D Kinetic Moiré Sculpture",
    description:
      "Artist and Engineer trying to do my part for the world through Art and Engineering 🚀 ",
    channelLink: "https://www.youtube.com/@JBVCreative",
    category: [Category.shorts],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/FcHGLyrKPyE",
    img: "https://yt3.googleusercontent.com/ytc/AGIKgqOtUwb_lQW86N8rQHzV_BzuW67MWH7Slaff4Tatgg=s176-c-k-c0x00ffffff-no-rj",
    title: "Up and Atom",
    modalTitle: "Breakthrough in mathematics! The einstein tile",
    description:
      "Making hard stuff less hard. Math, physics and computer science.",
    channelLink: "https://www.youtube.com/@upandatom",
    category: [Category.shorts],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/R1NW3YCbPbQ",
    img: "https://yt3.googleusercontent.com/gCQplVMHB8b9madG5cPNBVFJp8LVTBX0x7tjXa1JoD9uk_h8N0O0GeaZTiOHWrRUZsItL3oWcC4=s176-c-k-c0x00ffffff-no-rj",
    title: "Walt (oneminmicro)",
    modalTitle: "Baby Tears Under the Microscope - You will be AMAZED!",
    description:
      "Aussie based science content creator! It always looks bigger under a microscope!",
    channelLink: "https://www.youtube.com/@Oneminmicro",
    category: [Category.shorts],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/crEaYmapD44",
    img: "https://yt3.googleusercontent.com/U8iCRHhvytOb0QXW5lFd59uMiT_BKVo0fcJ_y7ZdAPEIwdUGvTjRRO5OpGKLMYua_Y1qanQu=s176-c-k-c0x00ffffff-no-rj",
    title: "Cleo Abram",
    modalTitle: "This is An Organ on a Chip",
    description:
      "Video journalist making optimistic tech explainers. Huge If True.",
    channelLink: "https://www.youtube.com/@CleoAbram",
    category: [Category.shorts],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/CTVd_WxblGI",
    img: "https://yt3.googleusercontent.com/ytc/AGIKgqPALCEl5eL-cpF5hFY4k6AucroIgYZjgmfAobGk=s176-c-k-c0x00ffffff-no-rj",
    title: "NileRed Shorts",
    modalTitle: "Vaporizing chicken in acid",
    description:
      "I'll be using this channel to cover smaller/shorter projects that feel quite different than what's seen on NileRed. I'll also be using this channel to combine all of the shorter videos that I've made on other platforms (Snapchat, Instagram, Tiktok, Twitter, etc), but I'll also make videos that will be posted here before anywhere else!",
    channelLink: "https://www.youtube.com/@NileRedShorts",
    category: [Category.shorts],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/PEDiV_Gab0o",
    img: "https://yt3.googleusercontent.com/ytc/AGIKgqNZqxEuF5JHShVLcPEogsBC6jbPjdz6P55E8dRUsA=s176-c-k-c0x00ffffff-no-rj",
    title: "Formlabs",
    modalTitle: "3D Printed Drone Toroidal Propellers vs Regular Propellers",
    description:
      "We make the world's best 3D printers so anyone can make anything.",
    channelLink: "https://www.youtube.com/@Formlabs",
    category: [Category.shorts],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/Njayu96Jb5w",
    img: "https://yt3.googleusercontent.com/ytc/AGIKgqMRg4sLM1OyhxtKBu8jUVJ4_mbkYOd3mfl-zPVdXw=s176-c-k-c0x00ffffff-no-rj",
    title: "ElectroBOOM",
    modalTitle: "Electronic Firefly Lantern",
    description:
      "Want to subconsciously learn while being entertained? Then subscribe!",
    channelLink: "https://www.youtube.com/@ElectroBOOM",
    category: [Category.shorts],
  },
  {
    id: COUNT++,
    src: "https://www.youtube.com/embed/OBYqSr-c6Ks",
    img: "https://yt3.googleusercontent.com/ytc/AGIKgqMLO4GwRiJiLiqVx5C21rI9s5Y-5zNPint6F5o9Ow=s176-c-k-c0x00ffffff-no-rj",
    title: "The Thought Emporium",
    modalTitle: "Genius Slime Mold Solves a Maze!",
    description:
      "Science is a bottomless well of some of the most amazing things this universe has to offer. There is so much amazing science and engineering that gets done, but most often the only ones who get to experience it first hand are those in expensive labs in universities. This channel aims to explore as much of fields of science and engineering as possible by showing real experiments and research being done in a fun and accessible way. While the goal is to show that even very hard science can be done in a DIY way, some projects will use some of the fancier toys that while less accessible, opens a broader range of projects that can be done. We cover everything from genetic engineering and tissue culture, to nuclear physics, to machine engineering, to chemical synthesis and more.",
    channelLink: "https://www.youtube.com/@thethoughtemporium",
    category: [Category.shorts],
  },

  // ** PUT THE NEW ENTRIES ABOVE **
  // Watch Data Template  --- Copy Paste Only (***DO NOT EDIT the id***)
  /* {
        id : COUNT++,
        src: "",
        img: "",
        title: "",
        modalTitle: "",
        description: "",
        channelLink: "",
        category: [Category.shorts]
    } */
];

export default watch;
