import React, { useEffect, useState, useMemo } from "react";
import { useParams, Link as ReactRouterLink } from "react-router-dom";
import {
  Box,
  HStack,
  Spacer,
  Heading,
  SimpleGrid,
  Text,
  Card,
  Spinner,
  Flex,
} from "@chakra-ui/react";

import { Link as ChakraLink } from "@chakra-ui/react";
import moment from "moment";
import { useGetArchivesContentQuery } from "../../redux/api/contentSlice";

// import { WatchCard } from '../Category/WatchCard'
import { ArchiveCard } from "./ArchiveCard";

// Cards from watch sections
import MetaDecorator from "../Utils/MetaDecorator";
import metaData from "../../Data/metaInfo";

const watch_data = [];
const sections = [];

export const ArchiveSection = (props) => {
  const { sectionName } = useParams();
  const { data, isLoading } = useGetArchivesContentQuery();
  // const [categoryMap, setCatMap] = useState({});

  // useEffect(() => {
  //   // const categoryMap = {};
  //   data?.forEach((item) => {
  //     const date = moment(item.customDate);
  //     const year = date.year();
  //     const month = date.month();
  //     const key = moment(item.customDate).format("MMMM-YYYY");
  //     if (!categoryMap[key]) {
  //       categoryMap[key] = { year, month, key, items: [] };
  //     }
  //     categoryMap[key].items.push(item);
  //   });
  // }, [data]);

  const memoizedCategoryMap = useMemo(() => {
    const categoryMap = {};
    data?.forEach((item) => {
      const date = moment(item.customDate);
      const year = date.year();
      const month = date.month();
      const key = moment(item.customDate).format("MMMM-YYYY");
      if (!categoryMap[key]) {
        categoryMap[key] = { year, month, key, items: [] };
      }
      categoryMap[key].items.push(item);
    });
    return categoryMap;
  }, [data]); // Update categoryMap only when data changes

  if (isLoading && !memoizedCategoryMap) {
    return (
      <Flex justify="center" align="center" height="80vh">
        <Spinner
          thickness="5px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </Flex>
    );
  }
  return (
    <>
      <Box maxW={"1100px"} mx={"auto"} mt={10}>
        <MetaDecorator
          title={`Archive > ${sectionName} | ${metaData.homePage.title}`}
          description={metaData.archivesPage.description}
        />
        <HStack>
          <Box w={1} bg={"cyan.500"} borderRadius={"md"} ml={4}>
            &nbsp;
          </Box>
          <Heading
            fontSize={"1.4rem"}
            mb={2}
            pl={1}
            color={"gray.500"}
          >{`${sectionName}`}</Heading>
          <Spacer />
          <ChakraLink
            as={ReactRouterLink}
            to={"/archives"}
            pr={4}
            color={"teal.500"}
          >
            Back
          </ChakraLink>
        </HStack>

        {/* {categories.map((category) => ( */}
        <SimpleGrid
          minChildWidth="166px"
          spacing={8}
          templateColumns="repeat(auto-fill, minmax(256px, 1fr))"
          mb={12}
          p={4}
        >
          {memoizedCategoryMap[sectionName].items.map((item, idx) => (
            <ArchiveCard
              key={item._id}
              url={item.src}
              img={item.imageSrc}
              title={item.title}
              description={item.description}
              date={item.customDate}
            />
          ))}
        </SimpleGrid>
      </Box>
    </>
  );
};
