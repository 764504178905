const brandName = 'Fuzzy Klarity'
const slogan = 'Curating the best of Science.'

const descriptions = {
    home: `Allow your sense of awe to flourish. Read | Watch | Listen | Discover. ${brandName + ' - ' +slogan}`,

    Discover:`Discover the best in popular science! Delve into our 'Content of the Day' section where we handpick the most captivating podcasts, videos, articles, and websites from across the web.`,
    shorts: `Feed your curiosity and expand your knowledge with our handpicked selection of
             the most enthralling scientific wonders condensed into bite-sized visual delights. 
             Science has never been this captivating!`,
    archives: `Discover the best in popular science! Delve into our 'Content of the Day' section where we handpick the most captivating podcasts, videos, articles, and websites from across the web.`,
    
    originals: "This section features original content created by us :)" ,    
    
    read: "This section features a curated selection of articles, blogs and interactive websites from around the web on a variety of science topics.",

    watch:"Watch: This section features a curated selection of videos from around the web on a variety of science topics.",

    listen: "This section features a curated selection of podcasts from around the web on a variety of science topics."


}

const metaData = {
    homePage: {
        title: `${brandName}`,
        description: descriptions.home,
        ogImage: null,
        twitterImageAlt: null
    },
    aboutPage: {
        title: `About | ${brandName}`,
        description: descriptions.home,
        ogImage: null,
        twitterImageAlt: null
    },
    
    archivesPage: {
        title: `Archive | ${brandName}`,
        description: descriptions.archives,
        ogImage: null,
        twitterImageAlt: null
    },
    originalsPage: {
        title: `Originals | ${brandName}`,
        description: descriptions.originals,
        ogImage: null,
        twitterImageAlt: null
    },
    watchPage: {
        title: `Watch | ${brandName}`,
        description: descriptions.watch,
        ogImage: null,
        twitterImageAlt: null
    },
    listenPage: {
        title: `Listen | ${brandName}`,
        description: descriptions.listen,
        ogImage: null,
        twitterImageAlt: null
    },
    readPage: {
        title: `Read | ${brandName}`,
        description: descriptions.read,
        ogImage: null,
        twitterImageAlt: null
    },
    discoverPage: {
        title: `Discover | ${brandName}`,
        description: descriptions.home,
        ogImage: null,
        twitterImageAlt: null
    },
    shortsPage: {
        title: `Shorts | ${brandName}`,
        description: descriptions.shorts,
        ogImage: null,
        twitterImageAlt: null
    },

}

/* Assignment of exotic meta data */

// Home page
metaData.homePage.ogTitle = metaData.homePage.title
metaData.homePage.ogDescription = metaData.homePage.description

// About
metaData.aboutPage.ogTitle = metaData.aboutPage.title
metaData.aboutPage.ogDescription = metaData.aboutPage.description

// Originals
metaData.originalsPage.ogTitle = metaData.originalsPage.title
metaData.originalsPage.ogDescription = metaData.originalsPage.description

// Listen
metaData.listenPage.ogTitle = metaData.listenPage.title
metaData.listenPage.ogDescription =metaData.listenPage.description

// Read
metaData.readPage.ogTitle = metaData.readPage.title
metaData.readPage.ogDescription = metaData.readPage.description

// Watch
metaData.watchPage.ogTitle = metaData.watchPage.title
metaData.watchPage.ogDescription = metaData.watchPage.description

// Shorts
metaData.shortsPage.ogTitle = metaData.shortsPage.title
metaData.shortsPage.ogDescription = metaData.shortsPage.description

// Discover
metaData.discoverPage.ogTitle = metaData.discoverPage.title
metaData.discoverPage.ogDescription = metaData.discoverPage.description

export default metaData