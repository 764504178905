import {
  AspectRatio,
  Box,
  Card,
  CardBody,
  Flex,
  HStack,
  Heading,
  Image,
  Link,
  SimpleGrid,
  Spacer,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Link as ChakraLink } from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useGetArchivesContentQuery } from "../../redux/api/contentSlice";
import MetaDecorator from "../../Components/Utils/MetaDecorator";
import metaData from "../../Data/metaInfo";
import moment from "moment";
import { ArchiveCard } from "../../Components/ArchiveSection/ArchiveCard";

/* 
function ArchivesCard({ url, img, title, description, date }) {
  return (
    <Card
      bg={"transparent"}
      _hover={{ filter: "brightness(1)", transform: "scale(1.005)" }}
      filter={"brightness(0.8)"}
      position={"relative"}
    >
      <Link href={url} isExternal style={{ textDecoration: "none" }}>
        <CardBody textAlign="left">
          <Card display={"flex"} top="5" position={"absolute"} zIndex="100">
            <Text
              color={"white"}
              minH="30px"
              p="0.5rem"
              borderBottomRightRadius={"lg"}
            >
              {moment(date).format("MMMM Do YYYY")}
            </Text>
          </Card>
          <AspectRatio overflow={"hidden"} borderRadius={"md"}>
            <Image objectFit={"cover"} src={img} alt={description} />
          </AspectRatio>

          <Stack mt="6" spacing="3">
            <Heading size="md" color={"#eee"}>
              {title}
            </Heading>
            <Text fontSize={"12px"} color={"#aaa"} noOfLines={5}>
              {description}
            </Text>
          </Stack>
        </CardBody>
      </Link>
    </Card>
  );
} */

const Archives = () => {
  const { data, isLoading } = useGetArchivesContentQuery();

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const categoryMap = {};
    data?.forEach((item) => {
      const date = moment(item.customDate);
      const year = date.year();
      const month = date.month();
      const key = `${year}-${month}`;
      if (!categoryMap[key]) {
        categoryMap[key] = { year, month, items: [] };
      }
      categoryMap[key].items.push(item);
    });
    setCategories(Object.values(categoryMap));
  }, [data]);

  if (isLoading) {
    return (
      <Flex justify="center" align="center" height="80vh">
        <Spinner
          thickness="5px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </Flex>
    );
  }

  return (
    <div>
      {/* <Box>{months}</Box> */}

      <Box maxW={"1100px"} mx={"auto"} mt={10}>
        <MetaDecorator
          title={metaData.archivesPage.title}
          description={metaData.archivesPage.description}
        />
        {categories.map((category) => (
          <Box
            maxW={"1200px"}
            mx={"auto"}
            mt={10}
            key={`${category.year}-${category.month}`}
          >
            <HStack>
              <Box w={1} bg={"cyan.500"} borderRadius={"md"} ml={4}>
                &nbsp;
              </Box>
              <Heading fontSize={"1.4rem"} mb={2} pl={1} color={"gray.500"}>
                {moment([category.year, category.month]).format("MMMM YYYY")}
              </Heading>
              <Spacer />
              <ChakraLink
                as={ReactRouterLink}
                to={`/archives/${moment([
                  category.year,
                  category.month,
                ]).format("MMMM-YYYY")}`}
                pr={4}
                color={"teal.500"}
              >
                Show All
              </ChakraLink>
            </HStack>

            <SimpleGrid minChildWidth="166px" spacing={8} templateColumns='repeat(auto-fill, minmax(256px, 1fr))' mb={12} p={4}>
              {category.items.map(
                (p, idx) =>
                  idx < 3 && (
                    <ArchiveCard
                      key={p._id}
                      url={p.src}
                      img={p.imageSrc}
                      title={p.title}
                      description={p.description}
                      date={p.customDate}
                    />
                  )
              )}
            </SimpleGrid>
          </Box>
        ))}
      </Box>
    </div>
  );
};

export default Archives;
