import React from "react";
import { useState } from "react";
import { LinkBox, LinkOverlay } from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import { Link as ChakraLink } from "@chakra-ui/react";
import {
  Box,
  Flex,
  HStack,
  Divider,
  Center,
  Stack,
  IconButton,
  Text,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { useLocation } from "react-router-dom";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const location = useLocation();
  const discoverPath = /^\/discover(\/[\w-]+)*$/i.test(location.pathname);
  const archivesPath = /^\/archives(\/[\w-]+)*$/i.test(location.pathname);
  const watchPath = /^\/watch(\/[\w-]+)*$/i.test(location.pathname);
  const originalsPath = /^\/originals(?:\/[\w-]+)*$/.test(location.pathname);
  const readPath = /^\/read(?:\/[\w-]+)*$/.test(location.pathname);
  const listenPath = /^\/listen(?:\/[\w-]+)*$/.test(location.pathname);
  const aboutPath = /^\/about(?:\/[\w-]+)*$/.test(location.pathname);
  const shortsPath = /^\/shorts(?:\/[\w-]+)*$/.test(location.pathname);

  // console.log(location.pathname) // Output: Current active route path

  return (
    <>
      <Box
        className="navbar"
        px={4}
        pos="sticky"
        top={0}
        zIndex="sticky"
        maxW={"1320px"}
        mx={"auto"}
        mb={shortsPath ? 0 : 10}
      >
        <Flex h={16} alignItems="center" justifyContent="space-between">
          <HStack spacing={8} alignItems="center">
            <LinkBox>
              <ReactRouterLink to="/">
                <LinkOverlay>
                  <Text className="logo" color="white" mr={2}>
                    Fuzzy Klarity
                  </Text>
                  <Text fontSize="xs" color="white">
                    Curating the best of science
                  </Text>
                </LinkOverlay>
              </ReactRouterLink>
            </LinkBox>
          </HStack>
          <IconButton
            size="md"
            icon={
              isOpen ? <CloseIcon fontSize={"1.2rem"} /> : <HamburgerIcon />
            }
            aria-label="Open Menu"
            display={{ md: "none" }}
            onClick={toggle}
            bg={"black"}
            fontSize={"2rem"}
            ml={0}
            color={"white"}
            _hover={{ bg: "black" }}
          />

          <HStack
            as="nav"
            spacing={4}
            display={{ base: "none", md: "flex" }}
            color={"white"}
            fontWeight={"semibold"}
            className="navbar"
          >
            <ChakraLink
              as={ReactRouterLink}
              _hover={{ textDecoration: "none", filter: "brightness(1.2)" }}
              filter={discoverPath ? "brightness(1.5)" : "brightness(0.5)"}
              to="/discover"
              color={"yellow.300"}
            >
              Discover
            </ChakraLink>
            <ChakraLink
              as={ReactRouterLink}
              _hover={{ textDecoration: "none", filter: "brightness(1.2)" }}
              filter={archivesPath ? "brightness(1.5)" : "brightness(0.5)"}
              to="/archives"
            >
              Archive
            </ChakraLink>

            <Center height="20px">
              <Divider orientation="vertical" />
            </Center>

            <ChakraLink
              as={ReactRouterLink}
              _hover={{ textDecoration: "none", filter: "brightness(1.2)" }}
              filter={originalsPath ? "brightness(1.5)" : "brightness(0.5)"}
              to="/originals"
            >
              Originals
            </ChakraLink>

            <ChakraLink
              as={ReactRouterLink}
              _hover={{ textDecoration: "none", filter: "brightness(1.2)" }}
              filter={readPath ? "brightness(1.5)" : "brightness(0.5)"}
              to="/read"
            >
              Read
            </ChakraLink>

            <ChakraLink
              as={ReactRouterLink}
              _hover={{ textDecoration: "none", filter: "brightness(1.2)" }}
              filter={watchPath ? "brightness(1.5)" : "brightness(0.5)"}
              to="/watch"
            >
              Watch
            </ChakraLink>

            <ChakraLink
              as={ReactRouterLink}
              _hover={{ textDecoration: "none", filter: "brightness(1.2)" }}
              filter={listenPath ? "brightness(1.5)" : "brightness(0.5)"}
              to="/listen"
            >
              Listen
            </ChakraLink>

            <ChakraLink
              as={ReactRouterLink}
              _hover={{ textDecoration: "none", filter: "brightness(1.2)" }}
              filter={shortsPath ? "brightness(1.5)" : "brightness(0.5)"}
              to="/shorts"
            >
              Shorts
            </ChakraLink>

            <ChakraLink
              as={ReactRouterLink}
              _hover={{ textDecoration: "none", filter: "brightness(1.2)" }}
              filter={aboutPath ? "brightness(1.5)" : "brightness(0.5)"}
              to="/about"
            >
              About
            </ChakraLink>
          </HStack>
        </Flex>

        {isOpen ? (
          <Box pb={4} display={{ md: "none" }} color={"white"}>
            <Stack
              as="nav"
              spacing={4}
              h={"80vh"}
              alignItems={"center"}
              justifyContent={"center"}
              py={"16"}
            >
              <ChakraLink
                as={ReactRouterLink}
                _hover={{ textDecoration: "none", filter: "brightness(1.2)" }}
                filter={discoverPath ? "brightness(1.5)" : "brightness(0.5)"}
                onClick={toggle}
                to="/discover"
              >
                Discover
              </ChakraLink>
              <ChakraLink
                as={ReactRouterLink}
                _hover={{ textDecoration: "none", filter: "brightness(1.2)" }}
                filter={archivesPath ? "brightness(1.5)" : "brightness(0.5)"}
                onClick={toggle}
                to="/archives"
              >
                Archive
              </ChakraLink>

              <ChakraLink
                as={ReactRouterLink}
                _hover={{ textDecoration: "none", filter: "brightness(1.2)" }}
                filter={originalsPath ? "brightness(1.5)" : "brightness(0.5)"}
                onClick={toggle}
                to="/originals"
              >
                Originals
              </ChakraLink>

              <ChakraLink
                as={ReactRouterLink}
                _hover={{ textDecoration: "none", filter: "brightness(1.2)" }}
                filter={readPath ? "brightness(1.5)" : "brightness(0.5)"}
                onClick={toggle}
                to="/read"
              >
                Read
              </ChakraLink>

              <ChakraLink
                as={ReactRouterLink}
                _hover={{ textDecoration: "none", filter: "brightness(1.2)" }}
                filter={watchPath ? "brightness(1.5)" : "brightness(0.5)"}
                onClick={toggle}
                to="/watch"
              >
                Watch
              </ChakraLink>

              <ChakraLink
                as={ReactRouterLink}
                _hover={{ textDecoration: "none", filter: "brightness(1.2)" }}
                filter={listenPath ? "brightness(1.5)" : "brightness(0.5)"}
                onClick={toggle}
                to="/listen"
              >
                Listen
              </ChakraLink>

              <ChakraLink
                as={ReactRouterLink}
                _hover={{ textDecoration: "none", filter: "brightness(1.2)" }}
                filter={shortsPath ? "brightness(1.5)" : "brightness(0.5)"}
                onClick={toggle}
                to="/shorts"
              >
                Shorts
              </ChakraLink>

              <ChakraLink
                as={ReactRouterLink}
                onClick={toggle}
                _hover={{ textDecoration: "none", filter: "brightness(1.2)" }}
                filter={aboutPath ? "brightness(1.5)" : "brightness(0.5)"}
                to="/about"
              >
                About
              </ChakraLink>
            </Stack>
          </Box>
        ) : null}
      </Box>
    </>
  );
};

export default Header;
