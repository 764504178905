import React from 'react'
import { SimpleGrid, Card, Box, Image, Link, Text, CardBody, Stack, Heading, AspectRatio } from "@chakra-ui/react";
import originals from "../../Data/originals"
import MetaDecorator from '../../Components/Utils/MetaDecorator';
import metaData from '../../Data/metaInfo';


function OriginalsCard({ url, img, title, description }) {
  return (
    <Card bg={'transparent'} _hover={{ filter: "brightness(1)", transform: 'scale(1.005)' }} filter={"brightness(0.8)"}>
      <Link href={url} isExternal style={{ textDecoration: 'none' }}>
        <CardBody textAlign="left">
          <AspectRatio overflow={'hidden'} borderRadius={'md'}>
            <Image
              objectFit={'cover'}
              src={img}
              alt={description}
            />
          </AspectRatio>

          <Stack mt='6' spacing='3'>
            <Heading size='md' color={'#eee'}>{title}</Heading>
            <Text fontSize={"12px"} color={'#aaa'} noOfLines={5}>{description}</Text>
          </Stack>
        </CardBody>

      </Link>
    </Card>
  );
}

const Originals = () => {
  return (

    <Box maxW={'1100px'} mx={'auto'} mt={10}  >
      <MetaDecorator title={metaData.originalsPage.title} description={metaData.originalsPage.description} />
      <SimpleGrid minChildWidth='255px' spacing={8} mb={12} p={4} >
        {originals.map((item) => (
          <OriginalsCard
            key={item.id}
            url={item.url}
            img={item.img}
            title={item.title}
            description={item.description}
          />
        ))}
      </SimpleGrid>
    </Box>
  )
}

export default Originals