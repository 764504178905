import { configureStore } from "@reduxjs/toolkit";
import { apliSlice } from "./api/apiSlice";
import contentReducer from "../redux/features/contentSlice"
import { setupListeners } from "@reduxjs/toolkit/query";


const store = configureStore({
    reducer: {
        [apliSlice.reducerPath]: apliSlice.reducer,
        content: contentReducer,
    },


    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apliSlice.middleware),
    devTools: true
});

setupListeners(store.dispatch);
export default store;